/** @format */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* ------ Add your CSS below this line (or @import your other CSS files). ------- */

/* @import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300;400;500;600;700&display=swap");

    html, body {
    overflow-x: hidden;
    width: 100%;}

body {
	@apply font-hind;
}

.no-js .dropdown {
  display: none; /* Hides dropdown menus until JS is enabled */
}



.uniform__potty {
	position: absolute;
	left: -9999px;
}.honeypot {
    position: absolute;
    left: -9999px;
}
/* @layer base {
	p {
		@apply mb-4;
	}
} */
/* Override dark mode styles */
body:not(.dark) .dark\:bg-gray-900 {
	background-color: #ffffff !important;
}

body:not(.dark) .dark\:text-white {
	color: #000000 !important;
}

.underline-effect::after {
	content: "";
	display: block;
	width: 0;
	height: 2px;
	background-color: #adcb00;
	transition: width 0.3s;
	position: absolute;
	bottom: 0;
	left: 0;
}

.underline-effect:hover::after,
.is-active::after {
	width: 100%;
}

.he-1 {
	@apply text-4xl;
}

.he-2 {
	@apply text-3xl;
}

.he-3 {
	@apply text-2xl;
}

.he-4 {
	@apply text-xl;
}

.he-5 {
	@apply text-lg;
}

.he-6 {
	@apply text-base;
}

.para {
	@apply text-base leading-relaxed;
}

/* Home page fader */
/* @keyframes fade {
	0% {
		opacity: 1;
	}

	33.33% {
		opacity: 0;
	}

	66.67% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.fade {
	animation: fade 12s infinite;
}

.fade:nth-child(1) {
	animation-delay: 0s;
}

.fade:nth-child(2) {
	animation-delay: 4s;
}

.fade:nth-child(3) {
	animation-delay: 8s;
} */

.fade {
  @apply opacity-0 absolute inset-0 w-full h-[450px] transition-opacity duration-3000 ease-in-out;
}

.fade.active {
  @apply opacity-100;
}

